import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Form, Spinner, InputGroup, Button,
} from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import MultiSelect from 'react-multiselect-checkboxes';
import QueryString from 'query-string';
import {
  CustomTable, ErrorHandler, Svg, CustomDropdown, CustomModal, DatePicker,
} from '../../component/common';
import {
  retailerStoreList, storeCategory, allocationTeam, storeLifeCycleState, ramTeam,
  storeTagOptions,
  storeTrainingDate,
  storeProfile,
} from '../../assets/api/axios';
import { ProcessingStatus } from '../../component/derived/table-list';
import { Constant, Storage, Utils } from '../../utilities';
import ExotelCallPatchDetails from '../../component/derived/call-logs/ExotelCallPatchDetails';
import Permission from '../../access&permissions/permission';
import { validateRegex } from '../../utilities/FormValidations';
import { getUserName } from '../../utilities/Storage';

function renderStatus(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    p, limit: l, trainingStartDate, trainingEndDate,
  } = param;
  const {
    q = '',
    deliveryStatus = '',
    assignedTo = '',
    categoryId = '',
    onDelivery = '',
    dueBefore = '',
    dueAfter = '',
    lifeCycleState = '',
    tag = '',
    code = '',
    bankStatus = '',
    subscriptionPlans = '',
    hasPendingSettlements,
    paymentStatus = '',
    classOfStore = '',
    firstOrderVerificationStatus = '',
    trainingBy = '',
    verificationCallStatus = '',
    isPromotionDone = '',
    isBrandingDone = '',
    isDelisted = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  trainingStartDate = Number(trainingStartDate) || '';
  trainingEndDate = Number(trainingEndDate) || '';
  return ({
    l,
    p,
    q,
    deliveryStatus,
    assignedTo,
    categoryId,
    onDelivery,
    dueBefore,
    dueAfter,
    lifeCycleState,
    code,
    tag,
    bankStatus,
    hasPendingSettlements,
    subscriptionPlans,
    paymentStatus,
    classOfStore,
    firstOrderVerificationStatus,
    trainingStartDate,
    trainingEndDate,
    trainingBy,
    verificationCallStatus,
    isPromotionDone,
    isBrandingDone,
    isDelisted,
  });
};

class RetailerList extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let storeCategories = Storage.getStoreCategory() || [];
    if (
      storeCategories.categories
      && storeCategories.categories.length > 0
      && storeCategories.categories[0].id
    ) {
      storeCategories = [];
    }
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(Permission.TRAINING_ALLOCATION_WRITE);
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      retailers: null,
      loading: true,
      error: false,
      searchText: param.q,
      param,
      storeCategories: [],
      initiateCall: false,
      phoneNumber: '',
      phoneNumberCopied: null,
      dueBeforeState: new Date(),
      dueAfterState: new Date(),
      allocationTeamFilters: [],
      tags: [],
      storeLifeCycleStateFilters: [],
      showShiftDateModal: false,
      shiftDateByDays: '',
      shiftDateStatus: '',
      shiftedForStoresMessage: null,
      performingAction: '',
      selectedId: '',
      updateStoreErrorMsg: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    const { storeCategories } = this.state;
    if (!storeCategories) {
      storeCategory('get').then((res) => {
        Storage.setStoreCategory({
          categories: res.data.results,
          lastUpdatedTime: new Date(),
        });
        this.setState({ storeCategories: res.data.results });
      });
      this.handleLoad();
      this.handleGetFilterData();
      return;
    }
    storeCategory('get').then((res) => {
      Storage.setStoreCategory({
        categories: res.data.results,
        lastUpdatedTime: new Date(),
      });
      this.setState({
        storeCategories: res.data.results,
      }, () => {
        this.handleLoad();
        this.handleGetFilterData();
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleGetFilterData = () => {
    allocationTeam('GET')
      .then((res) => {
        this.setState({
          allocationTeamFilters: res.data.results,
        }, () => {
          storeLifeCycleState('GET')
            .then((res1) => {
              this.setState({
                storeLifeCycleStateFilters: res1.data.results,
              });
            })
            .catch((err) => err);
        });
      })
      .catch((err) => err);
    storeTagOptions('GET')
      .then((res) => {
        this.setState({ tags: res.data.results });
      })
      .catch((err) => err);
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, q, deliveryStatus, assignedTo, categoryId,
      onDelivery, dueBefore, lifeCycleState, dueAfter,
      tag, bankStatus, hasPendingSettlements, subscriptionPlans, paymentStatus, classOfStore,
      firstOrderVerificationStatus, trainingBy, verificationCallStatus,
      isPromotionDone, isBrandingDone,
      trainingStartDate, trainingEndDate, isDelisted, code, storeHavingReview,
      sortBy,
      // isDelistedStore,
    } = newParam;
    const offset = (p - 1) * l;
    // eslint-disable-next-line react/no-unused-state
    this.setState({ offset });
    retailerStoreList(
      'get',
      {
        page: p,
        limit: l,
        searchText: q,
        deliveryStatus,
        assignedTo,
        categoryId,
        onDelivery,
        dueBefore,
        dueAfter,
        lifeCycleState,
        tag,
        code,
        bankStatus,
        hasPendingSettlements,
        subscriptionPlans,
        paymentStatus,
        classOfStore,
        firstOrderVerificationStatus,
        trainingBy,
        verificationCallStatus,
        trainingStartDate,
        trainingEndDate,
        isPromotionDone,
        isBrandingDone,
        isDelisted,
        storeHavingReview,
        sortBy,
        // isDelistedStore,
      },
      this.source.token,
    )
      .then((res) => {
        this.setState({
          retailers: res.data,
          loading: false,
          searchText: q,
          rowsPerPage: l,
          page: p,
          param: { ...newParam },
          performingAction: '',
        }, () => {
          if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
            Object.keys(newParam).forEach((item) => {
              if (!newParam[item]) {
                delete newParam[item];
              }
            });
            history.push({
              path: pathname,
              search: QueryString.stringify(newParam),
            });
          }
        });
      }).catch(() => {
        this.setState({
          loading: false,
          error: true,
          performingAction: '',
        });
      });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleTrainingDateChange = (date, code) => {
    storeTrainingDate(
      'POST',
      {
        date,
        storeCode: code,
        editorName: getUserName(),
      },
      this.source.token,
    )
      .then(() => {
        this.handleLoad();
      })
      .catch((err) => err);
  }

  handleTrainingType = (trainingType, storeId) => {
    this.setState({
      performingAction: 'UPDATE_TRAINING_TYPE',
      selectedId: storeId,
    });
    storeProfile(
      'PATCH',
      storeId,
      {
        trainingType,
      },
    ).then(() => {
      this.handleLoad();
    }).catch(() => {
      this.setState({
        updateStoreErrorMsg: 'Oops Something Went Wrong. Retry',
      });
    });
    this.retryPatchStore = () => {
      this.setState({
        updateStoreErrorMsg: '',
      });
      this.handleTrainingType(trainingType, storeId);
    };
  }

  handleTrainingAllocation = (trainingBy, storeId) => {
    this.setState({
      performingAction: 'UPDATE_TRAINING_ALLOCATION',
      selectedId: storeId,
    });
    storeProfile(
      'PATCH',
      storeId,
      {
        trainingBy,
      },
    ).then(() => {
      this.handleLoad();
    }).catch(() => {
      this.setState({
        updateStoreErrorMsg: 'Oops Something Went Wrong. Retry',
      });
    });

    this.retryTrainingAllocation = () => {
      this.setState({
        updateStoreErrorMsg: '',
      });
      this.handleTrainingAllocation(trainingBy, storeId);
    };
  }

  handleTrainingDateFilter = (trainingStartDate, trainingEndDate) => {
    const { param } = this.state;
    if (trainingStartDate !== param.trainingStartDate
      || trainingEndDate !== param.trainingEndDate) {
      this.handleRequestProcessing({
        trainingStartDate,
        trainingEndDate,
        p: 1,
      });
    }
  }

  handleOnChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  handleChangeDateTime = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: new Date(value),
    });
  }

  submitDueBeforeTime = () => {
    const { dueBeforeState, param } = this.state;
    if (dueBeforeState !== param.dueBefore) {
      this.handleRequestProcessing({
        // dueBefore: dueBeforeState.getTime(),
        dueBefore: `${moment(dueBeforeState).format('YYYY-MM-DDT23:59:00')}`,
        p: 1,
      });
    }
  }

  submitDueAfterTime = () => {
    const { dueAfterState, param } = this.state;
    if (dueAfterState !== param.dueAfter) {
      this.handleRequestProcessing({
        // dueBefore: dueBeforeState.getTime(),
        dueAfter: `${moment(dueAfterState).format('YYYY-MM-DDT23:59:00')}`,
        p: 1,
      });
    }
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === '')) {
      return `${placeholderButtonLabel}: All`;
    }
    return `${placeholderButtonLabel}: ${value.length} selected`;
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  onSubmitSearchText = () => {
    const {
      searchText, param,
    } = this.state;
    if (searchText !== param.q) {
      this.handleRequestProcessing({ q: searchText, p: 1 });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p, q,
    } = param;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source;
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
      searchText: q,
    });
  }

  resetFilters = () => {
    const { param } = this.state;
    const { l } = param;
    if (
      Object.keys(param).find((item) => (
        (item !== 'l') && (item !== 'p')
        && param[item]))) {
      this.handleRequestProcessing({
        ...getQueryParams({ l, p: 1 }),
      });
    }
  }

  handleShiftDateByDays = () => {
    const { shiftDateByDays, param } = this.state;
    this.setState({
      shiftDateStatus: 'submitting',
    });
    const shiftDatePayload = {
      shiftDateByDays,
      searchText: param.q,
      ...(
        param.deliveryStatus ? {
          deliveryStatus: param.deliveryStatus,
        } : {}
      ),
      ...(
        param.onDelivery ? {
          onDelivery: param.onDelivery,
        } : {}
      ),
      ...(
        param.dueAfter ? {
          dueAfter: param.dueAfter,
        } : {}
      ),
      assignedTo: param.assignedTo,
      categoryId: param.categoryId,
      dueBefore: param.dueBefore,
      lifeCycleState: param.lifeCycleState,
      ...(
        param.bankStatus ? {
          bankStatus: param.bankStatus,
        } : {}
      ),
      ...(
        param.hasPendingSettlements ? {
          hasPendingSettlements: param.hasPendingSettlements,
        } : {}
      ),
      ...(
        param.subscriptionPlans ? {
          subscriptionPlans: param.subscriptionPlans,
        } : {}
      ),
      ...(
        param.paymentStatus ? {
          paymentStatus: param.paymentStatus,
        } : {}
      ),
      ...(
        param.classOfStore ? {
          classOfStore: param.classOfStore,
        } : {}
      ),
      ...(
        param.firstOrderVerificationStatus ? {
          firstOrderVerificationStatus: param.firstOrderVerificationStatus,
        } : {}
      ),
      ...(
        param.trainingBy ? {
          trainingBy: param.trainingBy,
        } : {}
      ),
      ...(
        param.verificationCallStatus ? {
          verificationCallStatus: param.verificationCallStatus,
        } : {}
      ),
      ...(
        param.isPromotionDone ? {
          isPromotionDone: param.isPromotionDone,
        } : {}
      ),
      ...(
        param.isBrandingDone ? {
          isBrandingDone: param.isBrandingDone,
        } : {}
      ),
      ...(
        param.isDelisted ? {
          isDelisted: param.isDelisted,
        } : {}
      ),
    };
    ramTeam(
      'POST',
      shiftDatePayload,
    ).then((res) => {
      this.setState({
        shiftDateByDays: '',
        shiftDateStatus: 'success',
        shiftedForStoresMessage: res.data.message,
      });
    }).catch(() => {
      this.setState({
        shiftDateStatus: 'error',
      });
    });
  }

  render() {
    const {
      rowsPerPage, page, retailers, loading, param, phoneNumberCopied,
      error, searchText, storeCategories, initiateCall, phoneNumber,
      dueBeforeState, dueAfterState, allocationTeamFilters, storeLifeCycleStateFilters,
      showShiftDateModal, shiftDateStatus, shiftDateByDays, shiftedForStoresMessage,
      tags, performingAction, selectedId, updateStoreErrorMsg,
    } = this.state;
    const { userPermission } = this.props;
    const newAllocTeams = allocationTeamFilters.map((allocTeam) => ({
      label: allocTeam,
      value: allocTeam,
    }));
    const newLifeCycles = storeLifeCycleStateFilters.map((lifeCycle) => ({
      label: lifeCycle,
      value: lifeCycle,
    }));
    const tagFilters = tags.map((tag) => ({
      label: tag,
      value: tag,
    }));

    const servicing = (data) => {
      if (!data.deliveryStatus && data.isPermenentlyClose) {
        return (
          <span
            className="text-danger fs-01"
          >
            PERMANENTLY CLOSED
          </span>
        );
      }
      return (renderStatus(data.deliveryStatus));
    };

    const headers = [
      {
        key: 'code',
        displayText: 'Code',
        renderer: (data) => (
          <Link
            to={`/retailer/${data?.code}?${QueryString.stringify(Utils.deleteEmptyField(param))}`}
            className="text-primary"
          >
            {data?.code}
          </Link>
        ),
      },
      {
        key: 'name',
        displayText: 'Store',
      },
      {
        key: 'ownerName',
        displayText: 'Owner Name',
      },
      {
        key: 'phoneNumber',
        displayText: 'Primary',
        renderer: (data) => (
          <>
            <Button
              variant="link"
              className="p-0 fs-01"
              onClick={() => {
                window.open(window.location.href, '_blank');
                this.setState({
                  initiateCall: true,
                  phoneNumber: data?.phoneNumber,
                });
              }}
              disabled={!userPermission.includes(Permission.CALL_TO_RETAILER)
                || !Storage.getUserNumber()}
            >
              {data?.phoneNumber}
            </Button>
            <Button
              variant="link"
              onClick={() => {
                Utils.copyToClipboard(data?.phoneNumber);
                this.setState({
                  phoneNumberCopied: data?.code,
                }, () => {
                  setTimeout(() => {
                    this.setState((state) => ({
                      phoneNumberCopied: state.phoneNumberCopied === data?.code
                        ? null : state.phoneNumberCopied,
                    }));
                  }, 1000);
                });
              }}
              disabled={phoneNumberCopied === data?.code}
            >
              {
                phoneNumberCopied === data?.code
                  ? (
                    <Svg
                      svg="circleDone"
                      width="24"
                      fill={Constant.Color.GREEN}
                    />
                  ) : (
                    <Svg
                      svg="copy"
                      width={24}
                      fill={Constant.Color.PRIMARY}
                    />
                  )
              }
            </Button>
          </>
        ),
      },
      {
        key: 'alternatePhoneNumber',
        displayText: 'Secondary',
        renderer: (data) => (
          <Button
            variant="link"
            className="p-0 fs-01"
            onClick={() => {
              window.open(window.location.href, '_blank');
              this.setState({
                initiateCall: true,
                phoneNumber: data?.alternatePhoneNumber,
              });
            }}
            disabled={!userPermission.includes(Permission.CALL_TO_RETAILER)
              || !Storage.getUserNumber()}
          >
            {data?.alternatePhoneNumber}
          </Button>
        ),
      },
      {
        key: 'whatsappNumber',
        displayText: 'Whatsapp Number',
        renderer: (data) => <span>{data?.whatsappNumber || 'NA'}</span>,
      },
      {
        key: 'isWhatsappNotificationOpted',
        displayText: 'Whatsapp Notifications Opted',
        renderer: (data) => renderStatus(data?.isWhatsappNotificationOpted),
      },
      {
        key: 'storeTypeName',
        displayText: 'Shop Category',
      },
      {
        key: 'city',
        displayText: 'City',
      },
      {
        key: 'pin_code',
        displayText: 'PinCode',
      },
      {
        key: 'TrainingRequestDate',
        displayText: 'Training Request Date',
        renderer: (data) => Utils.dateString(data?.reqTrainingDate),
      },
      {
        key: 'TrainingDate',
        displayText: 'Training Date',
        renderer: (data) => (
          <DatePicker
            onApply={(date) => {
              this.handleTrainingDateChange(
                new Date(date.startDate).getTime(),
                data.code,
              );
            }}
            minDate={new Date()}
            maxDate=""
            startDate={data.assignedTrainingDate}
            clearDateSymbol={false}
            onClear={() => { }}
          />
        ),
      },
      {
        key: 'trainingType',
        displayText: 'Training Type',
        renderer: (data) => {
          if (
            performingAction === 'UPDATE_TRAINING_TYPE'
            && selectedId === data.code
            && !updateStoreErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                  justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          if (
            performingAction === 'UPDATE_TRAINING_TYPE'
            && selectedId === data.code && updateStoreErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                  justify-content-center"
              >
                <Button
                  className="fs-01 rounded-0 mr-2"
                  size="sm"
                  onClick={this.retryPatchStore}
                >
                  <Svg
                    svg="retry"
                    width="0.8rem"
                    fill={Constant.Color.WHITE}
                  />
                </Button>
                <Button
                  variant="secondary"
                  className="fs-01 rounded-0"
                  size="sm"
                  onClick={() => {
                    this.setState({
                      updateStoreErrorMsg: '',
                      performingAction: '',
                    });
                  }}
                >
                  <Svg
                    svg="arrow"
                    width="0.8rem"
                    fill={Constant.Color.BLACK}
                    transform="rotate(-90deg)"
                  />
                </Button>
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.handleTrainingType(e.target.value, data.code);
                }
              }}
              value={data.trainingType}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
              disabled={!data.assignedTrainingDate}
            >
              <option value="">Select</option>
              <option value="Activation_Training_Call">Activation Training Call</option>
              <option value="Activation_Training_G-Meet">Activation Training G-Meet</option>
              <option value="Activation_Repeat_training_Call">Activation Repeat training Call</option>
              <option value="Activation_Repeat_training_G-Meet">Activation Repeat training G-Meet</option>
              <option value="Activation_Ready_For_Order">Activation Ready For Order</option>
              <option value="PSP_Training">PSP Training</option>
              <option value="Order_Fulfillment_Training">Order Fulfillment Training</option>
            </select>
          );
        },
      },
      {
        key: 'trainingBy',
        displayText: 'Training Allocation',
        renderer: (data) => {
          if (
            performingAction === 'UPDATE_TRAINING_ALLOCATION'
            && selectedId === data?.code
            && !updateStoreErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                  justify-content-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  size="sm"
                />
              </div>
            );
          }
          if (
            performingAction === 'UPDATE_TRAINING_ALLOCATION'
            && selectedId === data?.code && updateStoreErrorMsg
          ) {
            return (
              <div
                className="pt-1 d-flex align-item-center
                  justify-content-center"
              >
                <Button
                  className="fs-01 rounded-0 mr-2"
                  size="sm"
                  onClick={this.retryTrainingAllocation}
                >
                  <Svg
                    svg="retry"
                    width="0.8rem"
                    fill={Constant.Color.WHITE}
                  />
                </Button>
                <Button
                  variant="secondary"
                  className="fs-01 rounded-0"
                  size="sm"
                  onClick={() => {
                    this.setState({
                      updateStoreErrorMsg: '',
                      performingAction: '',
                    });
                  }}
                >
                  <Svg
                    svg="arrow"
                    width="0.8rem"
                    fill={Constant.Color.BLACK}
                    transform="rotate(-90deg)"
                  />
                </Button>
              </div>
            );
          }
          return (
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.handleTrainingAllocation(e.target.value, data?.code);
                }
              }}
              value={data?.trainingBy}
              className={`p-1 ${performingAction ? 'pointer-event-none' : ''}`}
              disabled={!data?.assignedTrainingDate || !this.canEdit}
            >
              <option value="">Select</option>
              <option value="rushab@lovelocal.in">Rushab Kale</option>
              <option value="rajashree@lovelocal.in">Rajashree Chowkekar</option>
              <option value="shubhamdubey@mpaani.com">Subham Dubey</option>
              <option value="rohinipatel@lovelocal.in">Rohini Patel</option>
            </select>
          );
        },
      },
      {
        key: 'verificationCallStatus',
        displayText: 'Verification Call Status',
      },
      {
        key: 'createdOn',
        displayText: 'Sign up Date',
        renderer: (data) => Utils.dateString(data?.createdOn),
      },
      {
        key: 'onDelivering',
        displayText: 'On Delivery',
        renderer: (data) => renderStatus(data?.onDelivery),
      },
      {
        key: 'deliveryStatus',
        displayText: 'Servicing',
        renderer: (data) => servicing(data),
      },
      {
        key: 'customerCount',
        displayText: 'Customers',
      },
      {
        key: 'productCount',
        displayText: 'SKUs',
      },
      {
        key: 'assignedTo',
        displayText: 'Team',
      },
      {
        key: 'storeLifeCycleStatus',
        displayText: 'Store Status',
      },
      {
        key: 'kycApprovedBy',
        displayText: 'Kyc Approved By',
      },
      {
        key: 'launchedOnlineBy',
        displayText: 'Launched Online By',
      },
      {
        key: 'activatedOn',
        displayText: 'Activation Date',
        renderer: (data) => Utils.dateString(data?.activatedOn),
      },
      {
        key: 'subscriptionPlan',
        displayText: 'Subscription Plan',
      },
      {
        key: 'isPaidStore',
        displayText: 'Payment Status',
        renderer: (data) => renderStatus(data?.isPaidStore),
      },
      {
        key: 'classOfStore',
        displayText: 'Class Of Shop',
      },
      {
        key: 'requestPlacedBy',
        displayText: 'Request placed by',
      },
      {
        key: 'isDelisted',
        displayText: 'Status',
        renderer: (data) => (data?.isDelisted === true ? 'Delisted' : ''),
      },
      {
        key: 'source',
        displayText: 'Source',
      },
    ];

    const filterConf = [{
      key: 'onDelivery',
      displayText: 'On Delivery',
      options: [
        {
          label: 'Active',
          value: 'true',
        },
        {
          label: 'Inactive',
          value: 'false',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'deliveryStatus',
      displayText: 'Delivery Status',
      options: [
        {
          label: 'Active',
          value: 'true',
        },
        {
          label: 'Inactive',
          value: 'false',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'categoryId',
      displayText: 'Category',
      options: (storeCategories || []).map((category) => ({
        label: category.displayName,
        value: (category.id).toString(),
      })),
    },
    {
      key: 'assignedTo',
      displayText: 'Team',
      options: [
        ...newAllocTeams,
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'lifeCycleState',
      displayText: 'State',
      options: [
        ...newLifeCycles,
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'bankStatus',
      displayText: 'Bank Account Status',
      options: [
        {
          label: 'Missing',
          value: 'Missing',
        },
        {
          label: 'Verification Pending',
          value: 'Pending',
        },
        {
          label: 'Verified',
          value: 'Verified',
        },
        {
          label: 'Rejected',
          value: 'Rejected',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'hasPendingSettlements',
      displayText: 'Has Pending Settlements',
      options: [
        {
          label: 'True',
          value: 'true',
        },
        {
          label: 'False',
          value: 'false',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'subscriptionPlans',
      displayText: 'Subscription Plan',
      options: [
        {
          label: 'Advanced Growth',
          value: 'ADVANCED_GROWTH',
        },
        {
          label: 'Enterprise',
          value: 'ENTERPRISE',
        },
        {
          label: 'Key Account',
          value: 'KEY_ACCOUNT',
        },
        {
          label: 'Actual Key Account',
          value: 'ACTUAL_KEY_ACCOUNT',
        },
        {
          label: 'Potential Key Account',
          value: 'POTENTIAL_KEY_ACCOUNT',
        },
        {
          label: 'Churn Key Account',
          value: 'CHURN_KEY_ACCOUNT',
        },
        {
          label: 'ALL',
          value: 'all',
        },
      ],
    },
    {
      key: 'paymentStatus',
      displayText: 'Payment Status',
      options: [
        {
          label: 'Paid',
          value: 'PAID',
        },
        {
          label: 'Unpaid',
          value: 'NOT_PAID',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'classOfStore',
      displayText: 'Class Of Shops',
      options: [
        {
          label: 'Class A+',
          value: 'Class A+',
        },
        {
          label: 'Class A',
          value: 'Class A',
        },
        {
          label: 'Class B',
          value: 'Class B',
        },
        {
          label: 'Class C',
          value: 'Class C',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'firstOrderVerificationStatus',
      displayText: 'First Order Verification',
      options: [
        {
          label: 'Pending',
          value: 'PENDING',
        },
        {
          label: 'Approved',
          value: 'APPROVED',
        },
        {
          label: 'Rejected',
          value: 'REJECTED',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'trainingBy',
      displayText: 'Training By',
      options: [
        {
          label: 'Rushab Kale',
          value: 'rushab@lovelocal.in',
        },
        {
          label: 'Rajashree Chowkekar',
          value: 'rajashree@lovelocal.in',
        },
        {
          label: 'Subham Dubey',
          value: 'shubhamdubey@mpaani.com',
        },
        {
          label: 'Rohini Patel',
          value: 'rohinipatel@lovelocal.in',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'verificationCallStatus',
      displayText: 'Verification Call Status',
      options: [
        {
          label: 'Verified',
          value: 'Verified',
        },
        {
          label: 'Not Verified',
          value: 'NotVerified',
        },
        {
          label: 'Verification Pending',
          value: 'VerificationPending',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'isPromotionDone',
      displayText: 'Promotion Done',
      options: [
        {
          label: 'Done',
          value: 'true',
        },
        {
          label: 'Not Done',
          value: 'false',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'isBrandingDone',
      displayText: 'Branding Done',
      options: [
        {
          label: 'Done',
          value: 'true',
        },
        {
          label: 'Not Done',
          value: 'false',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'isDelisted',
      displayText: 'Delisted Store',
      options: [
        {
          label: 'True',
          value: 'true',
        },
        {
          label: 'False',
          value: 'false',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'tag',
        displayText: 'Tag',
        options: [
          ...tagFilters,
        ],
      },
    ];

    if (!retailers && loading) {
      return (
        <div
          className="h-100 d-flex align-items-center
           justify-content-center"
        >
          <Spinner
            animation="border"
            variant="primary"
          />
        </div>
      );
    }

    if (!retailers && !loading && error) {
      return (
        <div
          className="h-100 d-flex align-items-center
          justify-content-center"
        >
          <ErrorHandler
            retryLogic={() => this.retry()}
          />
        </div>
      );
    }

    if (retailers) {
      return (
        <div
          id="retailers"
          className="bg-white table-list"
        >
          <CustomModal
            show={showShiftDateModal}
            autoSize
            body={(
              <>
                {
                  shiftDateStatus === 'success'
                    ? (
                      <div
                        className="p-3"
                      >
                        <b
                          className="text-green"
                        >
                          {`Success: ${shiftedForStoresMessage}`}
                        </b>
                      </div>
                    ) : (
                      <div
                        className="p-3"
                      >
                        <b>Enter shift due days</b>
                        <div
                          className="pt-1"
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={shiftDateByDays}
                            onChange={(event) => {
                              if (!validateRegex('integerValue', event.target.value)) {
                                return;
                              }
                              this.setState({
                                shiftDateByDays: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )
                }
              </>
            )}
            onHide={() => {
              if (shiftDateStatus !== 'submitting') {
                this.setState({
                  shiftDateByDays: '',
                  shiftDateStatus: '',
                  showShiftDateModal: false,
                  shiftedForStoresMessage: null,
                });
              }
            }}
            footer={(
              <>
                {
                  shiftDateStatus === 'error' && (
                    <b
                      className="text-danger"
                    >
                      Error: Please try again!!
                    </b>
                  )
                }
                <Button
                  variant="link"
                  className="text-black"
                  disabled={shiftDateStatus === 'submitting'}
                  onClick={() => {
                    this.setState({
                      shiftDateByDays: '',
                      shiftDateStatus: '',
                      showShiftDateModal: false,
                      shiftedForStoresMessage: null,
                    });
                  }}
                >
                  <b>
                    {shiftDateStatus === 'success' ? 'OKAY' : 'GO BACK'}
                  </b>
                </Button>
                {
                  shiftDateStatus === 'submitting' ? (
                    <Spinner
                      variant="primary"
                      animation="border"
                      size="sm"
                    />
                  ) : (
                    <Button
                      variant="link"
                      disabled={Number(shiftDateByDays) <= 0}
                      onClick={() => {
                        this.handleShiftDateByDays();
                      }}
                    >
                      <b>
                        {shiftDateStatus === 'success' ? '' : 'SUBMIT'}
                      </b>
                    </Button>
                  )
                }
              </>
            )}
          />
          <ProcessingStatus
            show={loading || error}
            loading={loading}
            error={error}
            onRetry={() => this.retry()}
            onCancel={this.onCancel}
          />
          <ExotelCallPatchDetails
            show={initiateCall}
            onHide={() => {
              this.setState({
                initiateCall: false,
                phoneNumber: '',
              });
            }}
            phoneNumber={phoneNumber}
            action="CALL_TO_RETAILER"
          />
          <Container
            fluid
            className="px-0"
          >
            <Row
              className="mx-0"
            >
              <Col
                xs={24}
                className="px-0 filter-section py-2 bg-white"
              >
                <Row
                  className="mx-0"
                >
                  <Col
                    xs={24}
                    sm="auto"
                    className="px-2 py-1"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="fs-01 rounded-0"
                        value={searchText}
                        onChange={this.handleOnChange}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            this.onSubmitSearchText();
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>

                  {filterConf.map((item) => (
                    <Col
                      key={item.key}
                      xs="auto"
                      className="px-2 py-1"
                    >
                      <CustomDropdown
                        item={item}
                        onChange={this.handleDropdownChange}
                        selectedVal={param[item.key]}
                      />
                    </Col>
                  ))}
                  {
                    filterConfForMultiSelect.map((item) => (
                      <Col
                        key={item.key}
                        xs="auto"
                        className="px-2"
                      >
                        <div>
                          <MultiSelect
                            id={item.key}
                            placeholderButtonLabel={item.displayText}
                            getDropdownButtonLabel={this.getDropdownButtonLabel}
                            onChange={(value, event) => {
                              let selectedItemsString;
                              if (event.action === 'select-option' && event.option.value === '') {
                                selectedItemsString = item.options.reduce(
                                  (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                                );
                                this.handleDropdownChange({ [item.key]: selectedItemsString });
                              } else if (
                                event.action === 'deselect-option' && event.option.value === ''
                              ) {
                                this.handleDropdownChange({ [item.key]: '' });
                              } else {
                                selectedItemsString = value.reduce(
                                  (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                                );
                                this.handleDropdownChange({ [item.key]: selectedItemsString });
                              }
                            }}
                            selectedVal={param[item.key]}
                            options={item.options}
                            value={param[item.key]
                              ? (param[item.key].split(','))
                                .map((val) => ({ label: val, value: val }))
                              : []}
                            className="minw-200p fs-02"
                          />
                        </div>
                      </Col>
                    ))
                  }
                  <Col
                    xs="auto"
                    className="px-2 py-1"
                  >
                    <div
                      className="bg-light d-flex align-items-center justify-content-center"
                    >
                      <div className="px-2 fs-01">
                        Training Request Date
                      </div>
                      <DatePicker
                        isDateRange
                        onApply={(dateRange) => {
                          this.handleTrainingDateFilter(
                            new Date(dateRange.startDate).getTime(),
                            new Date(dateRange.endDate).getTime(),
                          );
                        }}
                        startDate={param.trainingStartDate}
                        endDate={param.trainingEndDate}
                        maxDate=""
                        onClear={() => {
                          this.handleRequestProcessing({
                            trainingStartDate: '',
                            trainingEndDate: '',
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row
                  className="mx-0"
                >
                  <Col
                    xs="auto"
                    className="py-1 px-2 d-flex"
                  >
                    <div>
                      <input
                        className="form-control rounded-0 fs-01 bg-light"
                        type="datetime-local"
                        name="dueBeforeState"
                        value={moment(dueBeforeState).format('YYYY-MM-DDT23:59')}
                        onChange={this.handleChangeDateTime}
                      />
                    </div>
                    <div>
                      <Button
                        variant="light"
                        className="fs-01 rounded-0 border"
                        onClick={this.submitDueBeforeTime}
                      >
                        Filter Due Before
                      </Button>
                    </div>
                  </Col>
                  <Col
                    xs="auto"
                    className="py-1 px-2 d-flex"
                  >
                    <div>
                      <input
                        className="form-control rounded-0 fs-01 bg-light"
                        type="datetime-local"
                        name="dueAfterState"
                        value={moment(dueAfterState).format('YYYY-MM-DDT23:59')}
                        onChange={this.handleChangeDateTime}
                      />
                    </div>
                    <div>
                      <Button
                        variant="light"
                        className="fs-01 rounded-0 border"
                        onClick={this.submitDueAfterTime}
                      >
                        Filter Due After
                      </Button>
                    </div>
                  </Col>
                  <Col
                    xs="auto"
                    className="py-1 px-2"
                  >
                    <Button
                      variant="primary"
                      className="fs-01 rounded-0"
                      onClick={() => {
                        this.setState({
                          showShiftDateModal: true,
                        });
                      }}
                      disabled={!userPermission.includes(Permission.SHIFT_DUE_DATE)}
                    >
                      Shift Due Date
                    </Button>
                  </Col>
                  <Col
                    xs="auto"
                    className="py-1 ml-auto px-3"
                  >
                    <Button
                      variant="outline-primary"
                      className="fs-01"
                      onClick={this.resetFilters}
                    >
                      Reset Filters
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                className="px-0 table-section"
              >
                <CustomTable
                  headers={headers}
                  content={retailers.data}
                  keyField="code"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={retailers.count}
                  hasPrev={retailers.prev}
                  hasNext={retailers.next}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return null;
  }
}

RetailerList.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RetailerList;
