import getBaseURL from './config';

export const banners = (uid) => `${getBaseURL('OO')}/homedelivery/v1/banners/${uid || ''}`;

export const bulkupload = () => `${getBaseURL('OO')}/homedelivery/v1/genericbulkupload/`;

export const cart = (cartId) => `${getBaseURL('OO')}/homedelivery/v1/cart/${cartId || ''}`;

export const customerAddress = (address) => `${getBaseURL('OO')}/homedelivery/v1/customerlocation/${address || ''}`;

export const customer = () => `${getBaseURL('OO')}/homedelivery/v1/customer/`;

export const customerOperation = () => `${getBaseURL('OO')}/homedelivery/v1/crmcustomeroperation/`;

export const exclusiveOffer = () => `${getBaseURL('OO')}/homedelivery/v1/exclusiveoffer/`;

export const exotelCall = () => `${getBaseURL('OO')}/homedelivery/v1/exotelcallroute/`;

export const exotelDetails = (id) => `${getBaseURL('OO')}/homedelivery/v1/exotelcalllogs/${id || ''}`;

export const imageUpload = () => `${getBaseURL('IMAGE')}/auxiliaryservice/v1/imageupload/`;

export const onlineStore = () => `${getBaseURL('OO')}/homedelivery/v1/onlineorderstorelist/`;

export const onlineOrderStoreWithMatrix = () => `${getBaseURL('OO')}/homedelivery/v1/onlineorderstorelistingwithmatrix/`;

export const order = (orderId) => `${getBaseURL('NEW_OO')}/crm/order${orderId ? `/${orderId}` : ''}`;

export const orderReplacement = (orderId) => `${getBaseURL('NEW_OO')}/crm/order-replacement${orderId ? `/${orderId}` : ''}`;

export const onlineProduct = () => `${getBaseURL('OO')}/homedelivery/v1/onlineorderstoreproduct/`;

export const settlement = () => `${getBaseURL('NEW_OO')}/crm/paymentsettlement`;

export const storeSettlement = () => `${getBaseURL('NEW_OO')}/crm/storesettlement`;

export const smsSent = () => `${getBaseURL('OO')}/homedelivery/v1/smslogs/`;

export const store = (code) => `${getBaseURL('OO')}/homedelivery/v1/store/${code || ''}`;

export const storeType = () => `${getBaseURL('OO')}/homedelivery/v1/reststoretype/`;

export const storeJourney = (code) => `${getBaseURL('OO')}/homedelivery/v1/storejourneyevents/${code}`;

export const storeTrainingDate = () => `${getBaseURL('OO')}/homedelivery/v1/logtranning/`;

export const shopAllocation = () => `${getBaseURL('OO')}/homedelivery/v1/shopallocation/`;

export const reconcile = (reconcileId) => `${getBaseURL('NEW_OO')}/crm/reconciledpayments${reconcileId ? `/${reconcileId}` : ''}`;

export const ramStore = (code) => `${getBaseURL('OO')}/homedelivery/v1/ramstore/${code || ''}`;

export const ramStoreContext = (code) => `${getBaseURL('OO')}/homedelivery/v1/ramstoredetail/${code}`;

export const userService = () => `${getBaseURL('OO')}/homedelivery/v1/usersession/`;

export const storecomments = (code) => `${getBaseURL('NEW_OO')}/crm/storecomments/${code}`;

export const ordercomments = (code) => `${getBaseURL('NEW_OO')}/crm/ordercomments/${code}`;

export const storeReview = () => `${getBaseURL('OO')}/homedelivery/v1/reviews/`;

export const storeProduct = (productId) => `${getBaseURL('OO')}/homedelivery/v1/retailerproduct/${productId || ''}`;

export const storeProductBulkEdit = () => `${getBaseURL('OO')}/homedelivery/v1/retailerproductbulkedit/`;

export const priceUpdate = () => `${getBaseURL('OO')}/homedelivery/v1/catalogjob/`;

export const productAddJob = () => `${getBaseURL('OO')}/homedelivery/v1/productaddjob/`;

export const productGroups = () => `${getBaseURL('OO')}/homedelivery/v1/productgroup/`;

export const productGroupProducts = () => `${getBaseURL('OO')}/homedelivery/v1/productgroup/product/`;

export const productGroupBulkAdd = (adminProcessedId) => `${getBaseURL('OO')}/homedelivery/v1/groupproductbulkadd/${adminProcessedId || ''}`;

export const libraryProduct = (productId) => `${getBaseURL('OO')}/homedelivery/v1/libraryproduct/${productId || ''}`;

export const retailerRequest = () => `${getBaseURL('OO')}/homedelivery/v1/retailerrequest/`;

export const allocationTeam = () => `${getBaseURL('OO')}/homedelivery/v1/allocationteam/`;

export const storeTagOption = () => `${getBaseURL('OO')}/homedelivery/v1/storetagoption/`;

export const storeLifeCycleState = () => `${getBaseURL('OO')}/homedelivery/v1/storelifecyclestate/`;

export const storeLifeCycle = () => `${getBaseURL('OO')}/homedelivery/v1/storelifecycle/`;

export const storeLifeCycleHistory = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/storelifecycle/?storeId=${storeId}`;

export const storeJobStatus = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/storejobstatus/${storeId}`;

export const misReport = () => `${getBaseURL('OO')}/homedelivery/v1/misreport/`;

export const ramStoresWithRating = () => `${getBaseURL('OO')}/homedelivery/v1/ramstore/?sortByRating=1`;

export const reviews = () => `${getBaseURL('OO')}/homedelivery/v1/reviews/`;

export const customersReview = () => `${getBaseURL('OO')}/homedelivery/v1/b2ccustomer/`;

export const reviewHistory = (reviewId) => `${getBaseURL('OO')}/homedelivery/v1/reviewhistory/${reviewId}`;

export const daily = (fid) => `${getBaseURL('OO')}/homedelivery/v1/feeds/${fid || ''}`;

export const eventFeed = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/eventfeed/${storeId || ''}`;

export const recommendedCategory = (categoryId) => `${getBaseURL('OO')}/homedelivery/v1/recommendedcategory/${categoryId || ''}`;

export const bulkAddProducts = () => `${getBaseURL('OO')}/homedelivery/v1/bulkadd/product/`;

export const bulkRemoveProducts = () => `${getBaseURL('OO')}/homedelivery/v1/bulkremove/product/`;

export const recommendedProducts = () => `${getBaseURL('OO')}/homedelivery/v1/recommendedproduct/`;

export const bulkAddRecommendedCatagory = () => `${getBaseURL('OO')}/homedelivery/v1/bulkadd/category/`;

export const testOrder = (code) => `${getBaseURL('OO')}/homedelivery/v1/fakeorder/${code || ''}`;

export const specialOffers = () => `${getBaseURL('OO')}/homedelivery/v1/specialoffers/`;

export const fraudStore = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/fraudv2/store/${storeId || ''}`;

export const couponOffer = (couponId) => `${getBaseURL('OO')}/homedelivery/v1/couponoffermeta/${couponId || ''}`;

export const referrerChecks = () => `${getBaseURL('OO')}/homedelivery/v1/referrerchecks/`;

export const productVariant = (productId) => `${getBaseURL('OO')}/homedelivery/v1/retailerproductvariant/${productId || ''}`;

export const ramstoredetail = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/ramstoredetail/${storeId}`;

export const ramTeam = () => `${getBaseURL('OO')}/homedelivery/v1/ramteam/`;

export const shopLogoReviews = () => `${getBaseURL('OO')}/homedelivery/v1/shoplogoapproval/`;

export const flashsalemeta = () => `${getBaseURL('OO')}/homedelivery/v1/flashsalemeta/`;

export const razorpayxmeta = () => `${getBaseURL('OO')}/homedelivery/v1/razorpayxmeta/`;

export const rewards = () => `${getBaseURL('OO')}/homedelivery/v1/scratchcard/`;

export const retailerStory = (storyId) => `${getBaseURL('OO')}/homedelivery/v1/retailerstory/${storyId || ''}`;

export const retailerStoryIcon = () => `${getBaseURL('OO')}/homedelivery/v1/retailerstoryicon/`;

export const productBulkUpload = () => `${getBaseURL('OO')}/homedelivery/v1/addproductbulkupload/`;

export const offerClass = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/storeofferclass/${storeId || ''}`;

export const adminCoupon = (couponId) => `${getBaseURL('OO')}/homedelivery/v1/admincoupon/${couponId || ''}`;

export const customProduct = () => `${getBaseURL('OO')}/homedelivery/v1/customproduct/`;

export const refundAdmin = () => `${getBaseURL('NEW_OO')}/crm/refunds`;

export const deliveryServiceability = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/deliveryserviceablity/${storeId || ''}`;

export const orderDeliverySettlement = () => `${getBaseURL('NEW_OO')}/crm/orderdeliverysettelment`;

export const queryTracker = () => `${getBaseURL('OO')}/homedelivery/v1/admingeneralb2cquerytracker/`;

export const adminCuratedLibraryGroup = (curatedGroupId) => `${getBaseURL('OO')}/homedelivery/v1/admincuratedlibrarygroup/${curatedGroupId || ''}`;

export const adminCuratedLibraryGroupMap = (curatedGroupMapId) => `${getBaseURL('OO')}/homedelivery/v1/admincuratedlibrarygroupmap/${curatedGroupMapId || ''}`;

export const registeredRegion = () => `${getBaseURL('OO')}/homedelivery/v1/registeredregion/`;

export const regionalLibrary = () => `${getBaseURL('OO')}/homedelivery/v1/adminregionallibrary/`;

export const productCategories = () => `${getBaseURL('OO')}/homedelivery/v1/category/`;

export const adminStoreCuratedGroup = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/adminstorecuratedgroup/${storeId || ''}`;

export const backgroundJobsdetail = () => `${getBaseURL('OO')}/homedelivery/v1/backgroundjobsdetail/`;

export const generateFundAccount = () => `${getBaseURL('OO')}/homedelivery/v1/retailerrazorpayfundaccount/`;

export const catalogBulkUpload = (code) => `${getBaseURL('OO')}/homedelivery/v1/uploadcatalogexport/${code || ''}`;

export const strongMatchProduct = (id) => `${getBaseURL('OO')}/homedelivery/v1/strongmatch/${id || ''}`;

export const weakMatchProduct = (id) => `${getBaseURL('OO')}/homedelivery/v1/weakmatch/${id || ''}`;

export const noMatchProduct = (id) => `${getBaseURL('OO')}/homedelivery/v1/nomatch/${id || ''}`;

export const customerDetails = () => `${getBaseURL('OO')}/homedelivery/v1/customerpanel/`;

export const validateOrderId = (orderId) => `${getBaseURL('OO')}/homedelivery/v1/isvalidredirectingorder/${orderId || ''}`;

export const lead = (leadId) => `${getBaseURL('OO')}/lms/v1/leads/${leadId || ''}`;

export const leadsMeta = () => `${getBaseURL('OO')}/lms/v1/lmsmeta/`;

export const agentMeta = () => `${getBaseURL('OO')}/lms/v1/agentmeta/`;

export const agentSms = () => `${getBaseURL('OO')}/lms/v1/leadsms/`;

export const agentCall = (leadId) => `${getBaseURL('OO')}/lms/v1/leadcall/${leadId || ''}`;

export const leadBulkUpload = () => `${getBaseURL('OO')}/lms/v1/leadbulkupload/`;

export const orderDeliveryLogs = (orderId) => `${getBaseURL('OO')}/homedelivery/v1/orderdeliverylogs/${orderId || ''}`;

export const reportCustomer = (customerId) => `${getBaseURL('OO')}/homedelivery/v1/reportcustomer/${customerId || ''}`;

export const markOrderFraud = (orderId) => `${getBaseURL('OO')}/homedelivery/v1/fraud/order/${orderId || ''}`;

export const markStoreFraud = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/fraud/store/${storeId || ''}`;

export const whatsappNotifications = () => `${getBaseURL('OO')}/homedelivery/v1/whatsappnotifications/`;

// export const ivrStatus = () => `${getBaseURL('OO')}/homedelivery/v1/ivrstatus/`;

export const alternateStoreProduct = () => `${getBaseURL('OO')}/homedelivery/v1/alternatestoreproduct/`;

export const libraryStoreProduct = () => `${getBaseURL('OO')}/homedelivery/v1/librarystoreproduct/`;

export const assignDeliveryPartner = (orderId) => `${getBaseURL('OO')}/homedelivery/v1/assigndeliverypartner/${orderId || ''}`;

export const fraudStores = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/fraudpanel/${storeId || ''}`;

export const rerouteableStore = (orderId) => `${getBaseURL('NEW_OO')}/crm/order/${orderId}/reroute/stores`;

export const rerouteOrderItems = (orderId) => `${getBaseURL('NEW_OO')}/crm/order/${orderId}/reroute/products`;

export const rerouteOrder = (orderId) => `${getBaseURL('NEW_OO')}/crm/order/${orderId}/reroute`;

export const sendbirdChannels = (channelURL) => `${getBaseURL('OO')}/homedelivery/v1/activechatlist/${channelURL || ''}`;

export const groupChannelLabels = () => `${getBaseURL('OO')}/homedelivery/v1/groupchannellabels/`;

export const crmOrderDispute = (orderId) => `${getBaseURL('NEW_OO')}/crm/order-dispute/${orderId || ''}`;

export const replacementOrderLogs = (orderId) => `${getBaseURL('NEW_OO')}/crm/order-replacement/${orderId || ''}`;

export const orderManager = () => `${getBaseURL('OO')}/homedelivery/v1/ordermanager/`;

export const orderManagerStatus = (orderManagerId) => `${getBaseURL('OO')}/homedelivery/v1/ordermanager/${orderManagerId}`;

export const orderManagerLogs = () => `${getBaseURL('OO')}/homedelivery/v1/ordermanagerlogs/`;

export const storeList = () => `${getBaseURL('OO')}/homedelivery/v1/storesearch/`;

export const panCardDetails = () => `${getBaseURL('OO')}/homedelivery/v1/kycverification/`;

export const retailerPayments = () => `${getBaseURL('OO')}/homedelivery/v1/storepayments/`;

export const listTags = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/listtags/${storeId || ''}`;

export const resurrectionStores = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/storeresurrection/${storeId || ''}`;

export const exportResurrectionStores = () => `${getBaseURL('OO')}/homedelivery/v1/storeresurrectionexport/`;

export const leadershipBoard = () => `${getBaseURL('OO')}/homedelivery/v1/leaderboard/`;

export const myReferral = () => `${getBaseURL('OO')}/homedelivery/v1/customerstorereferral/`;

export const myRecentOrders = () => `${getBaseURL('OO')}/homedelivery/v1/leaderboardrecentorders/`;

export const campaignAnalytics = () => `${getBaseURL('OO')}/homedelivery/v1/notificationanalytics/`;

export const storeCommentLogs = () => `${getBaseURL('OO')}/homedelivery/v1/storecommentlogs/`;

export const lmsStoreCommentLogs = () => `${getBaseURL('OO')}/lms/v1/storecommentlogs/`;

export const authRegister = () => `${getBaseURL('NEW_OO')}/authenticator/register`;

export const authLogin = () => `${getBaseURL('NEW_OO')}/authenticator/login`;

export const kycVerification = () => `${getBaseURL('OO')}/homedelivery/v1/kycverification/`;

export const crmCommunication = () => `${getBaseURL('NEW_OO')}/crm/comms`;

export const abandonCustomers = (customerId) => `${getBaseURL('OO')}/homedelivery/v1/abandoncartusers/${customerId || ''}`;

export const couponCampaigns = (campaignId, couponUsage) => `${getBaseURL('OO')}/homedelivery/v1/campaign/${campaignId || ''}${couponUsage ? `/${couponUsage}/` : ''}`;

export const couponCode = () => `${getBaseURL('OO')}/homedelivery/v1/coupon/`;

export const productExports = () => `${getBaseURL('OO')}/homedelivery/v1/productexports/`;

export const executors = (agentId) => `${getBaseURL('OO')}/homedelivery/v1/exotelmapping/${agentId || ''}`;

export const agentsData = () => `${getBaseURL('OO')}/homedelivery/v1/exotelprofiledata/`;

export const verificationCall = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/shopsignupcallverification/${storeId || ''}`;

export const getPromoterBrandingLogs = (id) => `${getBaseURL('NEW_OO')}/crm/promoter-activity${id ? `/${id}` : ''}`;

export const freeSampleCampaign = (campaignId) => `${getBaseURL('NEW_OO')}/crm/free-sample-campaign/${campaignId || ''}`;

export const addStoreOnCampaign = () => `${getBaseURL('NEW_OO')}/crm/free-sample-store/`;

export const marketplaceBrands = (brandId) => `${getBaseURL('NEW_OO')}/brand${brandId ? `/${brandId}` : ''}`;

export const marketplaceBrandOrders = () => `${getBaseURL('NEW_OO')}/brand-order/orders`;

export const marketplaceBrandOrderDetails = (orderId) => `${getBaseURL('NEW_OO')}/brand-order/${orderId || ''}`;

export const marketplaceBrandProducts = (productId) => `${getBaseURL('NEW_OO')}/brand/product/${productId ? `${productId}` : ''}`;

export const replaceOrderProducts = () => `${getBaseURL('OO')}/homedelivery/v1/replacedproducts/`;

export const videoUpload = () => `${getBaseURL('NEW_OO')}/videoupload`;

export const retailerProfile = (storeId) => `${getBaseURL('NEW_OO')}/crm/store/bio/${storeId || ''}`;

export const festiveCategory = (catId) => `${getBaseURL('NEW_OO')}/crm/festive-category${catId ? `/${catId}` : ''}`;

export const financeSettlementDashboard = () => `${getBaseURL('NEW_OO')}/crm/finance-dashboard/`;

export const updateVpaStatus = (storeId) => `${getBaseURL('NEW_OO')}/retailer/retailer-vpa-status/${storeId || ''}`;

export const switchSettlementMode = (storeId) => `${getBaseURL('OO')}/homedelivery/v1/settlement-mode_switch/${storeId}`;

export const platformFeeDashboard = () => `${getBaseURL('NEW_OO')}/crm/platform-dashboard`;

export const orderManagerList = (OMTag) => `${getBaseURL('NEW_OO')}/crm/om${OMTag ? `/${OMTag}` : ''}`;

export const OMLogs = (OMTag) => `${getBaseURL('NEW_OO')}/crm/om-logs${OMTag ? `/${OMTag}` : ''}`;

export const citySpecificRewards = () => `${getBaseURL('NEW_OO')}/crm/city-specific-reward`;

export const retailerWalletTransaction = (storeCode) => `${getBaseURL('NEW_OO')}/store/wallet/transactions/${storeCode}`;

export const retailerWalletInfo = (storeCode) => `${getBaseURL('NEW_OO')}/store/wallet/${storeCode}`;

export const rerouteDisposition = () => `${getBaseURL('OO')}/homedelivery/v1/rerouteorderdisposition/`;

export const orderCancellationReason = () => `${getBaseURL('NEW_OO')}/crm/order/cancel-reasons`;

export const getBDEViewVisitsLogs = (id) => `${getBaseURL('NEW_OO')}/crm/bde-activity${id ? `/${id}` : ''}`;

export const transactionMeta = (orderId) => `${getBaseURL('NEW_OO')}/crm/transaction-meta/${orderId}`;

export const orderCount = () => `${getBaseURL('NEW_OO')}/crm/order-count`;

export const ivrStatus = () => `${getBaseURL('NEW_OO')}/crm/ivrstatus`;

export const alternateStoreProducts = (storeId, searchText) => `${getBaseURL('NEW_OO')}/crm/crm-products/${storeId}/${searchText}`;

export const billDetails = (orderId) => `${getBaseURL('NEW_OO')}/crm/order/${orderId}/reroute/bill-details`;

export const modifications = (orderId) => `${getBaseURL('NEW_OO')}/crm/order/${orderId}/modifications`;

export const productHistory = (orderId) => `${getBaseURL('NEW_OO')}/crm/order/${orderId}/order-product-history`;

export const rerouteOrderDisposition = () => `${getBaseURL('NEW_OO')}/crm/rerouteorderdisposition`;

export const reListStore = () => `${getBaseURL('NEW_OO')}/crm/store-relist`;

export const grabOrder = () => `${getBaseURL('NEW_OO')}/crm/grab-order`;

export const flashSaleCampaignList = () => `${getBaseURL('NEW_OO')}/crm/flash-sale/list`;

export const flashSaleCampaign = () => `${getBaseURL('NEW_OO')}/crm/flash-sale`;

export const cityLocality = () => `${getBaseURL('NEW_OO')}/crm/flash-sale/city-locality`;

export const searchProduct = (productId) => `${getBaseURL('NEW_OO')}/search/product/${productId}/details`;

export const storeBulkUpload = () => `${getBaseURL('NEW_OO')}/crm/flash-sale/bulk-add-stores`;

export const removeStore = () => `${getBaseURL('NEW_OO')}/crm/flash-sale/remove-stores`;

export const storePinning = (action) => `${getBaseURL('NEW_OO')}/crm/store-pinning/${action}`;

export const getUPIOptions = () => `${getBaseURL('NEW_OO')}/crm/digital/payment-modes`;

export const llPlusPurchasesList = () => `${getBaseURL('NEW_OO')}/subscription/order-list`;

export const storeDeliveryStatusChange = (storeCode) => `${getBaseURL('NEW_OO')}/store/store-state/${storeCode}`;

export const deliveryHexes = (storeCode) => `${getBaseURL('NEW_OO')}/crm/${storeCode}/hex/list`;

export const specialNotes = () => `${getBaseURL('NEW_OO')}/store/special-notes`;

export const categoryCityList = () => `${getBaseURL('NEW_OO')}/store/category-city-list`;

export const deliveryBoy = (storeCode) => `${getBaseURL('NEW_OO')}/crm/${storeCode}/delivery-boy`;

export const kaeAsmFilter = () => `${getBaseURL('NEW_OO')}/crm/kae-asm-list`;

export const hexLogsInfo = (storeCode) => `${getBaseURL('NEW_OO')}/crm/${storeCode}/hex-logs`;

export const storeStatusAndTimings = (orderId) => `${getBaseURL('NEW_OO')}/crm/order/${orderId}`;
